import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Modal,
  IconButton,
  Stack,
  Image,
  Text,
  Link,
  DefaultButton,
  PrimaryButton,
  Persona,
  PersonaSize,
  Label,
  Dialog
} from '@fluentui/react';
import useTourStore from "../store/tourStore";


const ModelFinisher = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const {closeTour} = useTourStore();
  const RejectOffboard = () => {
    navigate('/');
  }
  const [activeTab, setActiveTab] = useState('onboarding');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const CloseWindowOnly = () => {
    navigate('/user-approver');
  }

  const CloseWindowByIncreaseingtheStep = () => {
    closeTour()
  }

  return (
    <Dialog
      isOpen={isOpen}
      onDismiss={onClose}
      isBlocking={false}
      containerClassName="rounded-xl !max-w-[600px]"
    >
      <div className="relative w-full">
        <IconButton
          iconProps={{iconName: 'Cancel'}}
          title="Close dialog"
          ariaLabel="Close dialog"
          onClick={CloseWindowByIncreaseingtheStep}
          styles={{root: {position: 'absolute', right: -30, top: -30}}}
        />
        <h1 className="font-bold text-5xl">
          Congratulations on finishing the Demo
        </h1>
        <div className="mt-[45px] text-center">Click the button below to restart the demo, or close this pop-up.</div>
        <PrimaryButton
          className="!mx-auto mt-[40px] bg-microsoft !text-white !font-normal	hover:bg-microsoft"
          onClick={onClose}
        >
          Restart demo
        </PrimaryButton>
      </div>
    </Dialog>
  );
};

export default ModelFinisher;
