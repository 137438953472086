import * as React from 'react';
import {
  Fabric,
  MessageBar,
  MessageBarType,
  Text,
  Stack,
  IconButton,
  TextField,
  Modal,
  Link,

  Icon
} from '@fluentui/react';
import { makeStyles, Tab, TabList } from "@fluentui/react-components";

const TeamsTab = () => {

  return (
<div class="teams-tabs">

  <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }} styles={{ root: { padding: 0,paddingLeft:20 ,height:60, borderBottom: '1px solid #ddd' } }}>
  <Stack.Item>
  <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
    <div style={{ color: "black", width: 30, height: 30, backgroundColor: '#fff', borderRadius: 3, display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', fontSize: 16 }}><img className='teams-tab-image' src='./HoundID_Icon.svg'></img></div>
    <Text style={{fontSize:16,fontWeight:"bold", paddingRight:5}} variant="large">HoundID Demo</Text>
      </Stack>
      </Stack.Item>
      <Stack.Item style={{fontSize:12}} className='stack-'>  
        Posts
        </Stack.Item>
        <Stack.Item style={{fontSize:12}} className='stack-'>  
        Files
        </Stack.Item>
        <Stack.Item style={{fontSize:12}} className='stack-'>  
        Notes
        </Stack.Item>
        <Stack.Item>
        <Tab className='teams-menu-tab' title="List"  text="list">
          HoundID
        </Tab>        
        </Stack.Item>
        
  
        <Stack.Item>
        <IconButton style={{fontSize:10,}} iconProps={{ iconName: 'Add' }} />
        </Stack.Item>


<Stack.Item grow />
{/* <Stack.Item>
<Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
<IconButton iconProps={{ iconName: 'Message' }} />
<IconButton iconProps={{ iconName: 'Video' }} />
<IconButton iconProps={{ iconName: 'MoreVertical' }} />
</Stack>
</Stack.Item> */}
</Stack>
</div>
  );

}
export default TeamsTab;
