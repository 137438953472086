import React from "react"
    const dropdownStyles = {
        root: {
          backgroundColor: 'white',
          width: '50% !important',
        },
        dropdown: {
          backgroundColor: 'white',
        },
        dropdownItem: {
          backgroundColor: 'white',
          color: 'black !improtant', 
          selectors: {
            ':hover': {
              backgroundColor: 'white',
              color: 'black',
            },
            ':focus': {
              backgroundColor: '#white',
              color: 'black',
            },
          },
        },
        // dropdownItemSelected: {
        //   backgroundColor: 'white',
        //   color: 'black',
        // },
        dropdownItemDisabled: {
          backgroundColor: 'lightgray',
          color: 'darkgray',
        },
        dropdownItemSelected: {
          backgroundColor: 'transparent', // Change this to your desired color
          selectors: {
            ':hover': {
              backgroundColor: 'transparent', // Change this to your desired color
            }
          }
        },
        dropdownItemSelectedAndDisabled: {
          backgroundColor: 'transparent', // Change this to your desired color
        },
        dropdownOptionText: {
          color: "black", // Ensure text color is inherited
        },
      };
      export default dropdownStyles