import React from 'react';
import { Stack, Text } from '@fluentui/react';
import NumberOfLicencesFree from '../utils/GetNumberOfLicences'


function getGroups() {
    const app1 = "Microsoft Office";
    const app2 = "Google Workspace";
    return () => {
        let apps;

        if (window.location.hostname === 'example.com') {
            apps = [app1, app2];
        } else {
            apps = [app1];
        }

        return apps;
    };
}
const listItemStyle = {
    listStyleType: 'none',
    position: 'relative',
    paddingLeft: '3em',
    marginBottom: '0.5em'
  };
  
  const listItemBeforeStyle = {
    content: '"- "',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    // margin: 'auto 0',
    color: '#000' // Adjust the color as needed
  };
  
  const renderList = (items) => (
    <ul style={{ marginTop: 0, paddingLeft: 0}}>
      {items.map((item, index) => (
        <li key={index} style={listItemStyle}>
          <span style={listItemBeforeStyle}></span>
          {item}
        </li>
      ))}
    </ul>
  );

const UserCustomLicence = ({ Department, AdditinalActions,AdditionalActionsOffboard ,AdditionalActionsOffboardOnly,AdditinalActionsOffBoardChecked }) => {
    const FreeLicences = NumberOfLicencesFree()
    // console.log("AdditionalActionsOffboard:", AdditionalActionsOffboard);

    console.log({ Department, AdditinalActions,AdditionalActionsOffboard ,AdditionalActionsOffboardOnly,AdditinalActionsOffBoardChecked })
    let userGroup = [];
    let userMailbox = [];
    let additinalActionsDisplay = [];
    let enterpriseApps = [];
    let userLicences = [];


    if (Department === "Marketing") {
        userGroup = ["Marketing Team", "Campaign Managers Group"];
        userMailbox = ["marketing@example.yourdomain.com"];
        enterpriseApps = [`Dynamics 365 Marketing (${FreeLicences.marketing} of 1000 Licenses available)`]
        userLicences = ["Office 365 E5","Copilot"];

    } else if (Department === "IT") {
        userGroup = ["IT Support Group", "Helpdesk Group"];
        userMailbox = ["support@example.yourdomain.com"];
        enterpriseApps = [`Azure DevOps (${FreeLicences.it} of 1000 Licenses available)`]
        userLicences = ["Microsoft 365 E3"]

    }else if (Department === "Sales") {
        userGroup = ["Sales Team", "Executive Sales Group"];
        userMailbox = ["sales@example.yourdomain.com"];
        enterpriseApps = [`Dynamics 365 Sales`]
        userLicences = ["Microsoft 365 E3"]

    } else if (Department === "HR") {
        userGroup = ["HR Team","Employee Relations Group"];
        userMailbox = ["hr@example.yourdomain.com"];
        enterpriseApps = [`Dynamics 365 Human Resources`]
        userLicences = ["Microsoft 365 E3"]

    } else if (Department === "Finance") {
        userGroup = ["Finance Team", "Budget Analysis Group"];
        userMailbox = ["finance@example.yourdomain.com"];
        enterpriseApps = [`Dynamics 365 Finance`];
        userLicences = ["Microsoft 365 E3","Office 365 E3"]

    }  else {
        userGroup = ["News Group"];
        userMailbox = ["support@company.com"];
        userLicences = ["Microsoft 365 E3","Office 365 E3"]

    }
    if (AdditinalActions) {
        additinalActionsDisplay = ["Get a User ID card", "Issue a laptop", "Issue company polo"];
    }
    if (AdditionalActionsOffboard == false) {
      const additinalActionOffboardDisplay = [];
  }
  if (AdditionalActionsOffboard == true) {
    const additinalActionOffboardDisplay = ["Collect Company ID", "Return Laptop", "Collect building pass"];
}
const additinalActionOffboardDisplay = AdditionalActionsOffboard 
  ? ["Collect Company ID", "Return Laptop", "Collect building pass"] 
  : [];
    
    // ,`this is the number of it licences ${FreeLicences.it} this is the amount of free marketing licences ${FreeLicences.marketing}`
    var data = {
        "Groups": userGroup,
        "Enterprise Apps": enterpriseApps,
        // "Licenses": [`Microsoft E5 (${FreeLicences.base} of 1000 licenses available)`,`TELSTRA Calling for O365 (${FreeLicences.base} of 10000 licenses available)`],
        "Licenses": userLicences,
        "Shared Mailboxes": userMailbox,
        // "Custom Actions": ["Print ID Badge", "Send OHS form", "Get Keys cut to building"],
        "Additional Actions": additinalActionsDisplay,
        ...(additinalActionOffboardDisplay.length > 0 && { "Additional Actions Offloads": additinalActionOffboardDisplay })

    };
    if (AdditinalActionsOffBoardChecked == true){
      console.log("fjlksljkf",additinalActionOffboardDisplay)
    // Override the data object with only the "Additional Actions Offloads" key
    data = {
      ...data, // Preserves the current keys in data if needed
      ...{"Additional Actions Offloads": ["Collect Company ID","Return Laptop","Collect building pass"]} 
    };
    }
    if (AdditinalActionsOffBoardChecked == false){
      console.log("fjlksljkf",additinalActionOffboardDisplay)
    // Override the data object with only the "Additional Actions Offloads" key
    data = {
      ...data, // Preserves the current keys in data if needed
      ...{"Additional Actions Offloads": []} 
    };
    }
    
    

    const renderList = (items) => (
        <ul  style={{ marginTop: 0, paddingLeft: 0 }}>
            {items.map((item, index) => (
                <li key={index} style={listItemStyle}>
                    <span style={listItemBeforeStyle}></span>
                    -  {item}
                </li>
            ))}
        </ul>
    );

    return (
        <Stack className='user-licance-info' tokens={{ childrenGap: 20 }} styles={{ root: { width:"100%" } }}>
        {Object.keys(data).map((category, index) => (
          <div key={index}>
            <Text variant="large" className='user-licence-header' styles={{ root: { fontWeight: 'bold',marginBottom:40 } }}>
              {category}:
            </Text>
            <div style={{width:"100%",height:"3rem"}}></div>
            {renderList(data[category])}
          </div>
        ))}
      </Stack>
    );
};

export default UserCustomLicence;
