
import React from 'react';

export const CleanUserStatus = ({ userStatus, returnedPage }) => {
    // console.log(userStatus)
    const onboardStatusMessages = {
        'Unapproved': 'Waiting For Approval',
        'OffboarderedWaitingForApproval': 'Completed',
        // 'OffBoardApproved': 'Completed',
        'OffBoardRejected': 'Completed',
        'InProgress': 'The process is currently in progress.',
        'Rejected' : 'Rejected',
        'Approved' : 'Completed',
        'OffBoardApproved': 'Completed'

            // Add more status mappings as needed
      };

    const offboardStatusMessages = {
        // 'Unapproved': 'The process is pending approval.',
        'OffboarderedWaitingForApproval': 'Waiting For Approval',
        'OffBoardRejected': 'Waiting For Approval',
        'OffBoardApproved': 'Off Board Approved',
        // 'zzzzz': 'OffBoardApproved',

        // 'InProgress': 'The process is currently in progress.',
        'Rejected' : 'Rejected'
        // Add more status mappings as
    }
    if (returnedPage === "Onboard") {
        // console.log("xxx" )

        // console.log(onboardStatusMessages[userStatus] )
        // console.log("xxx" )

        return onboardStatusMessages[userStatus] || 'Unknown status';
        
    } else if (returnedPage === "Offboard") {
        // console.log(userStatus )

        // console.log(offboardStatusMessages[userStatus] )
        // console.log("xxx" )
        return offboardStatusMessages[userStatus] || 'Unknown status';
    } else {
        return 'Invalid page';
    }
};

export default CleanUserStatus;
