import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Icon, mergeStyles} from '@fluentui/react';
import {Tab} from "@fluentui/react-components";
import useTourStore from "../store/tourStore";


function Navbar({activeTab, onTabClick}) {

  const iconClass = mergeStyles({
    marginRight: 5,
  });

  const location = useLocation();

  const locationPathname = location.pathname

  return (
    //   <div className="requests-container">
    <div className="header-row">
      <div className="column">
        <img
          className="logo-image"
          src="HoundID.png"
          alt="Hound ID Horizontal Logo"
        />
      </div>
      <div className="column end-column">
        <a
          role="tab"
          type="button"
          aria-selected={activeTab === 'onboarding'}
          className={`tab`}
          onClick={() => onTabClick('onboarding')}
          style={{paddingTop: 20}}
        >
          <Tab icon={<Icon iconName="List"  className={iconClass} />} title="List"  text="list">
            List
          </Tab>
        </a>
        <div role="tablist" aria-orientation="horizontal" className="tabs-container">
        </div>
      </div>
    </div>


    //   </div>
  );
}
export default Navbar;
