import React, { useEffect, useState } from 'react';
import { Spinner, SpinnerSize, Label, Icon } from '@fluentui/react';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { CleanUserStatus } from '../utils/CleanUserStatus';

initializeIcons();

const statusSteps = [
  { label: 'Waiting for approval' },
  { label: 'Completion' },
];

const OffLoadstatus = ({ ApprovalTime, stepDurations, status }) => {
  const returnedPage = "Offboard";
  console.log(status);
  const userStatus = status;
  console.log(userStatus);
  const statusMessage = CleanUserStatus({ userStatus, returnedPage });

  const [currentStep, setCurrentStep] = useState(0);
  console.log('offboarding unix time', ApprovalTime);

  const calculateCurrentStep = () => {
    if (ApprovalTime && stepDurations && stepDurations.length === statusSteps.length) {
      const startTime = ApprovalTime;
      const now = Math.floor(Date.now() / 1000);
      const elapsedTime = now - startTime;

      let accumulatedTime = 0;
      for (let i = 0; i < stepDurations.length; i++) {
        accumulatedTime += stepDurations[i];
        if (elapsedTime < accumulatedTime) {
          return i;
        }
      }
      return statusSteps.length - 1; // All steps completed
    }
    return 0;
  };

  useEffect(() => {
    // Initial calculation of current step
    setCurrentStep(calculateCurrentStep());

    // Set interval to update current step every second
    const interval = setInterval(() => {
      setCurrentStep(calculateCurrentStep());
    }, 1000); // Update every second

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [ApprovalTime, stepDurations]);

  return (
    <div className="requests-container">
      <div className="approval-status">
        <Label className="ms-Label newUserStatus">
          Status: {statusMessage}
        </Label>
        <div>
          {statusSteps.map((step, index) => (
            <div key={index} className="status-item">
              {status === "OffBoardApproved" ? (
                <Icon iconName="SkypeCircleCheck" className="status-completed-icon" />
              ) : status === "OffboarderedWaitingForApproval" ? (
                index === 0 ? (
                  <Spinner size={SpinnerSize.xSmall} className="status-item" />
                ) : (
                  <Icon iconName="LocationCircle" className="status-completed-icon" />
                )
              ) : index < currentStep ? (
                <Icon iconName="SkypeCircleCheck" className="status-completed-icon" />
              ) : index > currentStep && currentStep < statusSteps.length ? (
                <Spinner size={SpinnerSize.xSmall} className="status-spinner" />
              ) : currentStep <= statusSteps.length ? (
                <Icon iconName="SkypeCircleCheck" className="status-completed-icon" />
              ) : (
                <Icon iconName="SkypeCircleCheck" className="status-static-icon" />
              )}
              <Label className="ms-Label status-label">{step.label}</Label>
            </div>
          ))}
        </div>
        <br />
      </div>
    </div>
  );
};

export default OffLoadstatus;
