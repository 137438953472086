import React from 'react';
import { Stack, Text, IconButton, DefaultButton, Dropdown, DatePicker,Modal } from '@fluentui/react';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { useNavigate } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

// Initialize Fluent UI icons
initializeIcons();

const CalendarPage = () => {
  const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  const dates = ['24', '25', '26', '27', '28'];
  const hours = ['3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM'];
  const navigate = useNavigate();
  const handleClose = () => {
    // onClose(action, email);
    console.log("dsafhlhljfda")
    navigate.push('/');
};

  return (
    <div>
             <Modal
            isOpen={true}
            onClick={() => handleClose()}
            // onDismiss={() => onClose('Close')}
            isBlocking={false}
        >
      <a href="/">
    <div class="main-99 ms-Stack css-118 ms-Modal-scrollableContent scrollableContent-100">
      <div>
      </div>
      {/* <button  onClick={() => handleClose()}> */}
        
      {/* </button> */}

      <p>Click on the <svg fill="var(--colorNeutralForeground3BrandSelected)" class="___12fm75w f1w7gpdv fez10in fg4l7m0" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M14.75 10c.97 0 1.75.78 1.75 1.75v4.75a4.5 4.5 0 0 1-9 0v-4.75c0-.97.79-1.75 1.75-1.75h5.5Zm-7.62 0c-.35.42-.57.95-.62 1.53v4.97c0 .85.18 1.65.52 2.36A4 4 0 0 1 2 15v-3.24c0-.92.7-1.67 1.6-1.74l.15-.01h3.38Zm9.74 0h3.38c.97 0 1.75.78 1.75 1.75V15a4 4 0 0 1-5.03 3.87c.3-.63.48-1.32.53-2.06v-5.06c0-.67-.23-1.28-.63-1.75ZM12 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm6.5 1a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm-13 0a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Z" fill="var(--colorNeutralForeground3BrandSelected)"></path></svg></p>
      <p>to return to Trilogy Demo</p>
      </div>
      </a>
    </Modal>
    <div class="calender-container">
    <Stack verticalFill styles={{ root: { height: '100vh' } }}>
      {/* Header */}
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center" styles={{ root: { padding: '0 20px', borderBottom: '1px solid #e1e1e1' } }}>
        <Text variant="xxLarge">Calendar</Text>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
          <DefaultButton text="Join with an ID" />
          <DefaultButton text="Meet now" />
          <DefaultButton text="New meeting" />
        </Stack>
      </Stack>

      {/* Calendar Navigation */}
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center" styles={{ root: { padding: '10px 20px', borderBottom: '1px solid #e1e1e1' } }}>
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
          <IconButton iconProps={{ iconName: 'Calendar' }} title="Today" />
          <DatePicker
            placeholder="Select a date..."
            ariaLabel="Select a date"
          />
          <IconButton iconProps={{ iconName: 'ChevronLeft' }} title="Previous week" />
          <IconButton iconProps={{ iconName: 'ChevronRight' }} title="Next week" />
        </Stack>
        <Dropdown
          placeholder="Work week"
          options={[{ key: 'workWeek', text: 'Work week' }, { key: 'fullWeek', text: 'Full week' }]}
        />
      </Stack>

      {/* Calendar Grid */}
      <Stack horizontal verticalFill>
        {/* Time Column */}
        <Stack styles={{ root: { width: 60, borderRight: '1px solid #e1e1e1' } }}>
          {hours.map(hour => (
            <Stack key={hour} verticalAlign="center" styles={{ root: { height: 60, borderBottom: '1px solid #e1e1e1', textAlign: 'center' } }}>
              <Text variant="small">{hour}</Text>
            </Stack>
          ))}
        </Stack>

        {/* Days Columns */}
        <Stack horizontal styles={{ root: { flexGrow: 1 } }}>
          {weekDays.map((day, index) => (
            <Stack key={day} styles={{ root: { flexGrow: 1, borderRight: '1px solid #e1e1e1' } }}>
              <Stack horizontalAlign="center" styles={{ root: { padding: 10, borderBottom: '1px solid #e1e1e1' } }}>
                <Text variant="large">{dates[index]}</Text>
                <Text variant="small">{day}</Text>
              </Stack>
              {hours.map(hour => (
                <Stack key={hour} verticalAlign="center" styles={{ root: { height: 60, borderBottom: '1px solid #e1e1e1', textAlign: 'center' } }}>
                  {/* Empty slots for meetings */}
                </Stack>
              ))}
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
    </div>
    </div>
  );
};

export default CalendarPage;
