import React from 'react';



export const NumberOfUnapproved = () => {

    const storedUsers = sessionStorage.getItem('users');
    var NumberOfUnapprovedUsers = 0
    // Parse the JSON string into a JavaScript array
    const users = storedUsers ? JSON.parse(storedUsers) : [];
    users.forEach(user => {
        // console.log(user.Status)
        if (user.Status === "Unapproved" || user.Status === "OffboarderedWaitingForApproval") {
            NumberOfUnapprovedUsers = NumberOfUnapprovedUsers + 1
        }
    });

    // console.log("number of unapproved",NumberOfUnapprovedUsers)
    // Return the final email name
    return NumberOfUnapprovedUsers;
};

export default NumberOfUnapproved

// Named export for EmailNameCreator
// export { EmailNameCreator };

// Default export for CheckValidityAndExportTick
// export default CheckValidityAndExportTick;
