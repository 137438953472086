import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchBox, DefaultButton, DetailsList, DetailsListLayoutMode, SelectionMode, Text, DetailsRow } from '@fluentui/react';
import './../App.css';
import {CleanUserStatus} from '../utils/CleanUserStatus'
import useTourStore from "../store/tourStore";
import TourPopup from "./TourPopup";

const UserOnBoarding = ({setSelectedTab}) => {
  const navigate = useNavigate();
  const {currentTourStep, setTourState, nextTourStep} = useTourStore();
  const handleSubmit = (e) => {

  }
  // Retrieve the users data from sessionStorage
  const storedUsers = sessionStorage.getItem('users');
  // Parse the JSON string into a JavaScript array
  const users = storedUsers ? JSON.parse(storedUsers) : [];
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [searchInput, setSearchInput] = useState('');

  const handleSearch = (newValue) => {
    const query = newValue.toLowerCase();
    setSearchInput(query);
    const filtered = users.filter(user =>
      user.GeneratedEmail.toLowerCase().includes(query)
    );
    setFilteredUsers(filtered);
  };

  const handleOffboardUser = () => {
    navigate('/offboard-user');
    nextTourStep();
  };

  const columns = [
    { key: 'column1', name: 'Email', fieldName: 'Email', minWidth: 100, maxWidth: 200, isResizable: true },
    { key: 'column2', name: 'Status', fieldName: 'StatusClean', minWidth: 70, maxWidth: 90, isResizable: true },
    { key: 'column3', name: 'Created By', fieldName: 'CreatedBy', minWidth: 100, maxWidth: 150, isResizable: true },
    { key: 'column4', name: 'Create Date', fieldName: 'createdDateClean', minWidth: 100, maxWidth: 150, isResizable: true },
    { key: 'column5', name: 'Start Date', fieldName: 'StartDate', minWidth: 100, maxWidth: 150, isResizable: true },
  ];
  var preparedUsers = filteredUsers.map(user => {
    const hasSpecificStatus = user.Status === 'OffboarderedWaitingForApproval' || user.Status === 'OffBoardApproved';  // Replace 'specificStatus' with the actual status you want to check
    // if (user.Status != null) {

    const userStatus = user.Status
    const returnedPage = "Offboard"
    const statusMessage = CleanUserStatus({ userStatus, returnedPage });
    console.log(statusMessage,user.Status,user.GeneratedEmail)
    return {
      ...(hasSpecificStatus && { // Only add these fields if user has a status
      ...user, // Spread original user properties
      EmailName: user.GeneratedEmail || '',
      Email: user.GeneratedEmail ? `${user.GeneratedEmail}@${user.Company}.yourdomain.com` : '',
      CreatedBy: user.CreatedBy || 'Awesome User', // Assuming CreatedBy might be missing
      CreatedDateClean: user.CreatedDate || '',
      StatusClean: statusMessage,

      Status: user.Status,

        // Add any other fields that should be conditionally added
      })
    }
  // };
  });
  const isBlankRow = (row) => {
    // Define your own criteria for what constitutes a blank row
    return !row || Object.values(row).every(value => value === null || value === '');
  };

  preparedUsers = preparedUsers.filter(user => !isBlankRow(user));


  // Helper function to determine if a row is blank
  const showUserPage = (user) => {
    navigate('/user-info', { 
      state: {
        Infotarget: "Offboarding",
        Name: user.Name, 
        Email: user.Email, 
        UserName: user.GeneratedEmail.replace(/@example\.microsoft\.com$/, '') || '',
        Status: user.Status, 
        CreatedBy: user.CreatedBy, 
        CreateDate: user.CreateDate, 
        StartDate: user.StartDate,
        FirstName: user.FirstName, 
        LastName: user.LastName, 
        PrefferedLastName: user.PreferredLastName, 
        PrefferedFirstName: user.PreferredFirstName,
        CreatedDate: user.CreateDate
      } 
    });

    setTimeout(() => {
      setTourState({currentTourStep: 14})
    }, 3000)
  };

  const onRenderRow = (props) => {
    const customStyles = {
      root: {
        cursor: 'pointer'
      }
    };

    if (props) {
      return (
        <DetailsRow
          {...props}
          styles={customStyles}
          onClick={() => showUserPage(props.item)}
        />
      );
    }
    return null;
  };

  return (
    <div>
      <TourPopup
        caretDirection="right"
        show={currentTourStep === 5}
        text="Click here to offboard user"
        className="!fixed top-[285px] left-[65px]"
        onNext={handleOffboardUser}
        onPrev={() => {
          setSelectedTab('tab1')
          setTourState({currentTourStep: 4.1})
        }}
      />
      <TourPopup
        caretDirection="right"
        show={currentTourStep === 13}
        text="Click here to view status"
        className="!fixed top-[422px] left-[70px]"
        onNext={() => {
          showUserPage(preparedUsers?.[preparedUsers?.length - 1])
        }}
        onPrev={() => {
          setSelectedTab('tab1')
          setTourState({currentTourStep: 12})
        }}
      />
      <div className="onboarder-controls onboarder-onboarding">
        <DefaultButton text="Offboard User" className='onboarder-user-screen' onClick={handleOffboardUser} />
        <SearchBox
          placeholder="Search"
          onSearch={handleSearch}
          onChange={(_, newValue) => handleSearch(newValue)}
          value={searchInput}
          style={{ marginLeft: '20px', width: '300px' }}
          
        />

      </div>`
      {(currentTourStep === 5.1) && <div className='arrow-right step-9-arrow'>Click offboard user</div>}

      <DetailsList
        items={preparedUsers}
        columns={columns}
        setKey="set"
        onRenderRow={onRenderRow}
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={SelectionMode.none}
        styles={{ root: { marginTop: '20px' } }}
      />

      <div className="pagination-controls">
        <DefaultButton text="Previous" disabled />
        <Text variant="medium" className="pagenator-textfeild">Page 1 of 1</Text>
        <DefaultButton text="Next" disabled />
      </div>
    </div>
  );
};

export default UserOnBoarding;
