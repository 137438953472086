import React, { useState,useEffect } from 'react';
import UserOnboarding from './UserOnBoarding';
import UserOffboarding from './UserOffBoarding';
import Navbar from './Navbar';
import TeamsUI from './TeamUI'
import { Pivot, PivotItem, Text, mergeStyles, getTheme  } from '@fluentui/react';
import { Stack, Link, IconButton } from '@fluentui/react';
import { makeStyles, Tab, TabList } from "@fluentui/react-components";
// import type { TabListProps } from "@fluentui/react-components";
import TeamsTab from './TeamsTab';
import { updateArrowPosition } from '../utils/positionArrow';

import useTourStore from "../store/tourStore";
import TourPopup from "./TourPopup";
import {useNavigate} from "react-router-dom";

const theme = getTheme();

function OnboarderOffboarder(props) {
// Define custom styles for the dropdown component
  const {currentTourStep, setTourState} = useTourStore();
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateArrowPosition();
    }, 100);

    return () => clearInterval(intervalId); // Clean up on component unmount
  }, []);
  useEffect(() => {
    // Initial update
    updateArrowPosition();
  }, []);
  const pivotStyles = {
    root: {
      display: 'flex',
      justifyContent: 'center',
      borderBottom: `1px solid ${theme.palette.neutralLight}`,
    },
    link: {
      fontSize: '16px',
      fontWeight: 'normal',
      margin: '0 20px',
      padding: '10px 0',
      color: 'black',
      borderBottom: '2px solid transparent',
      selectors: {
        ':hover': {
          color: theme.palette.themePrimary,
        },
      },
    },
    linkIsSelected: {
      fontWeight: 'bold',
      color: 'black',
      borderBottom: `2px solid ${theme.palette.themePrimary}`,
    },
  };
  const [selectedTab, setSelectedTab] = React.useState("tab1");

  return (
    <div>
      <TourPopup
        caretDirection="left"
        show={currentTourStep === 4.1 || currentTourStep === 12}
        text="Now click the offboard tab"
        className="!fixed top-[228px] left-[540px]"
        onNext={() => {
          setSelectedTab('tab2')

          if (currentTourStep === 4.1) {
            setTourState({currentTourStep: 5})
          }

          if (currentTourStep === 12) {
            setTourState({currentTourStep: 13})
          }
        }}
        onPrev={() => {
          if (currentTourStep === 4.1) {
            setTourState({currentTourStep: 4})
            return;
          }

          navigate('/user-approver')
        }}
      />
      <TeamsTab />
      <div class="requests-container">
        <Navbar></Navbar>
        <div>
          <div className="tab-buttons">
            <TabList {...props} selectedValue={selectedTab} onTabSelect={(event, data) => {
              if (currentTourStep === 4.1) {
                setTourState({currentTourStep: 5})
              }

              if (currentTourStep === 12) {
                setTourState({currentTourStep: 13})
              }
              setSelectedTab(data.value)
            }}>
              <Tab value="tab1">Onboarding</Tab>
              <Tab value="tab2">Offboarding</Tab>
            </TabList>
            <div>
              {selectedTab === "tab1" && <UserOnboarding />}
              {selectedTab === "tab2" && <UserOffboarding setSelectedTab={setSelectedTab} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default OnboarderOffboarder;
