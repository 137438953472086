
import React from 'react';



export const NumberOfLicencesFree = () => {

    const storedUsers = sessionStorage.getItem('users');
    var NumberOfUsersBaseLicence = 1000
    var NumberOfUsersITLicence = 1000
    var NumberOfUsersMarketingLicence = 1000


    // Parse the JSON string into a JavaScript array
    const users = storedUsers ? JSON.parse(storedUsers) : [];
    users.forEach(user => {

        console.log(user)
        if (["OffboarderedWaitingForApproval", "OffBoardRejected", "Approved", "OffboarderedWaitingForApproval", "OffBoardRejected"].includes(user.Status)) {
            NumberOfUsersBaseLicence = NumberOfUsersBaseLicence - 1
            if (user.Department === "IT"){
                NumberOfUsersITLicence = NumberOfUsersITLicence - 1

            }
            if (user.Department === "Marketing"){
                NumberOfUsersMarketingLicence = NumberOfUsersMarketingLicence - 1
            }
        }


    });


    // console.log("number of unapproved",NumberOfUnapprovedUsers)
    // Return the final email name
    const NumberOfLicencesFree = {"it":NumberOfUsersITLicence,"marketing":NumberOfUsersMarketingLicence,"base":NumberOfUsersBaseLicence}
    return NumberOfLicencesFree;
};

export default NumberOfLicencesFree

// Named export for EmailNameCreator
// export { EmailNameCreator };

// Default export for CheckValidityAndExportTick
// export default CheckValidityAndExportTick;
