import React, { useState, useEffect } from 'react';
import {
  TextField, PrimaryButton, Dropdown, DatePicker, Checkbox, Stack, Label, IDropdownOption, getBackgroundShade
} from '@fluentui/react';
import Navbar from "./Navbar";
import { EmailNameCreator } from './../utils/functions';
import { CheckValidityAndExportTick } from './../utils/CheckValidityTick';
import { useNavigate } from 'react-router-dom';
import { Icon, Spinner } from '@fluentui/react';
import TeamsTab from './TeamsTab';
import UserCustomLicence from './UserCustomLicence';
import dropdownStyles from '../styles/DropdownStyle';
import useTourStore from "../store/tourStore";
const AddUser = () => {
  const {setTourState} = useTourStore();
  const navigate = useNavigate();
  const [roleOptions, setRoleOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  var [departmentTasks, setDepartmentTasks] = useState(null);
  var [AdditinalActions, setAdditinalActionTasks] = useState(null);
  var dropdownsList = null;
  const [formData, setFormData] = useState({
    Company: '',
    FirstName: '',
    LastName: '',
    PreferredFirstName: '',
    PreferredLastName: '',
    StartDate: '',
    EndDate: '',
    MobilePhone: '',
    OfficePhone: '',
    Title: '',
    Building: '',
    OfficeLocation: '',
    Department: '',
    emailAddress: '',
    Status: 'Unapproved',
    Role: ''
  });
  const [startDateError, setStartDateError] = useState('');
  const [isFormValid, setIsFormValid] = useState(true);

  const customTextFieldStyles = {
    field: {
      '::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      MozAppearance: 'textfield', // For Firefox
    },
  };

  const rolesMapping = {
    IT: [{ key: 'ITSupportSpecialist', text: 'IT Support Specialist' }],
    Marketing: [{ key: 'MarketingManager', text: 'Marketing Manager' }],
    Sales: [{ key: 'SalesExecutive', text: 'Sales Executive' }],
    HR: [{ key: 'HRCoordinator', text: 'HR Coordinator' }],
    Finance: [{ key: 'FinanceAnalyst', text: 'Finance Analyst' }],
  };

  const [users, setUsers] = useState([]);
  const [emailName, setEmailName] = useState('');
  var [companyDomainName, setCompanyDomainName] = useState('');
  const [emailValidity, setEmailValidity] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const data = [];
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      const value = sessionStorage.getItem(key);
      data.push({ key, value });
    }
    setUsers(JSON.parse(sessionStorage.getItem('users') || '[]'));
  }, []);

  const handleDateChange = (date, name) => {
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: date ? date.toISOString().split('T')[0] : '',
      };

      if (name === 'StartDate' && formData.EndDate && new Date(date) > new Date(formData.EndDate)) {
        setStartDateError('Start Date cannot be after End Date');
        setIsFormValid(false);
      } else if (name === 'EndDate' && formData.StartDate && new Date(date) < new Date(formData.StartDate)) {
        setStartDateError('End Date cannot be before Start Date');
        setIsFormValid(false);
      } else {
        setStartDateError('');
        setIsFormValid(true);
      }

      return updatedFormData;
    });
  };

  const handleDropdownChange = (event, option, name) => {
    if (name === 'Company') {
      console.log(option);
      setCompanyDomainName(option.key);
    }
    if (name === 'Department') {
      setRoleOptions(rolesMapping[option.key] || []);
      formData.Role = ''; // Reset Role when Department changes
    }

    if (name === 'Department') {
      if (option.key === 'it') {
        departmentTasks = 'it';
        setDepartmentTasks(departmentTasks);
      } else if (option.text === 'marketing') {
        departmentTasks = 'marketing';
        setDepartmentTasks(departmentTasks);
      } else {
        departmentTasks = option.key;
        setDepartmentTasks(departmentTasks);
      }
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: option.key,
    }));
  };

  const handleCheckboxChange = (ev, isChecked) => {
    console.log(`The option has been changed to ${isChecked}.`);
    AdditinalActions = isChecked;
    setAdditinalActionTasks(AdditinalActions);

    setFormData((prevFormData) => ({
      ...prevFormData,
      ['additionalActions']: isChecked,
    }));
  };

  const handleChange = (e, option) => {
    console.log(option);
    console.log(e);

    setLoading(true);
    setSubmitted(false);

    setTimeout(() => {
      setLoading(false);
      setSubmitted(true);
    }, 500);

    const { name, value } = e.target || option;

    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: value,
      };

      const email = EmailNameCreator({
        FirstName: updatedFormData.FirstName,
        LastName: updatedFormData.LastName,
        PreferredFirstName: updatedFormData.PreferredFirstName,
        PreferredLastName: updatedFormData.PreferredLastName,
      });

      const emailValidity = CheckValidityAndExportTick({
        FirstName: updatedFormData.FirstName,
        LastName: updatedFormData.LastName,
        PreferredFirstName: updatedFormData.PreferredFirstName,
        PreferredLastName: updatedFormData.PreferredLastName,
      });

      setEmailValidity(emailValidity);
      setEmailName(email);

      return updatedFormData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = EmailNameCreator({
      FirstName: formData.FirstName,
      LastName: formData.LastName,
      PreferredFirstName: formData.PreferredFirstName,
      PreferredLastName: formData.PreferredLastName,
    });

    const CreatedTime = new Date();
    const createdDateClean = CreatedTime.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });

    formData.createdDateClean = createdDateClean;
    formData.GeneratedEmail = email;
    const newUsers = [...users, formData];
    sessionStorage.setItem('users', JSON.stringify(newUsers));
    setUsers(newUsers);
    setTourState({currentTourStep: 1})
    navigate('/');
    companyDomainName = 'example';
    setCompanyDomainName(companyDomainName);
  };

  return (
    <div>
      <TeamsTab />
      <div className="requests-container">
        <Navbar />
        <form onSubmit={handleSubmit}>
          <Stack style={{ marginBottom: 20 }} tokens={{ childrenGap: 10 }}>
            {/* First Section */}
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Dropdown
                label="Company"
                name="Company"
                selectedKey={formData.Company}
                onChange={(event, option) => handleDropdownChange(event, option, 'Company')}
                options={[
                  { key: 'example', text: 'Example (example.yourdomain.com)' },
                  { key: 'hybrid', text: 'Hybrid (hybrid.yourdomain.com)' },
                ]}mic
                required
                styles={{ root: { width: '50%' } }}
              />
              <Dropdown
                label="User Type"
                name="userType"
                selectedKey={formData.userType}
                onChange={(event, option) => handleDropdownChange(event, option, 'userType')}
                options={[
                  { key: 'user', text: 'User' },
                  { key: 'contractor', text: 'Contractor' },
                ]}
                required
                styles={{ root: { width: '50%' } }}
              />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DatePicker
                name="StartDate"
                label="Start Date"
                value={formData.StartDate ? new Date(formData.StartDate) : null}
                onSelectDate={(date) => handleDateChange(date, 'StartDate')}
                required
                styles={{ root: { width: '50%' } }}
              />
              <DatePicker
                name="EndDate"
                label="End Date"
                value={formData.EndDate ? new Date(formData.EndDate) : null}
                onSelectDate={(date) => handleDateChange(date, 'EndDate')}
                required
                styles={{ root: { width: '50%' } }}
              />
            </Stack>
            {startDateError && <p style={{ color: 'red' }}>{startDateError}</p>}
            <hr />

            {/* Second Section */}
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <TextField
                label="First Name"
                name="FirstName"
                value={formData.FirstName}
                onChange={handleChange}
                required
                styles={{ root: { width: '50%' } }}
              />
              <TextField
                label="Last Name"
                name="LastName"
                value={formData.LastName}
                onChange={handleChange}
                required
                styles={{ root: { width: '50%' } }}
              />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <TextField
                label="Preferred First Name"
                name="PreferredFirstName"
                value={formData.PreferredFirstName}
                onChange={handleChange}
                styles={{ root: { width: '50%' } }}
              />
              <TextField
                label="Preferred Last Name"
                name="PreferredLastName"
                value={formData.PreferredLastName}
                onChange={handleChange}
                styles={{ root: { width: '50%' } }}
              />
            </Stack>
            <input
              type="hidden"
              id="emailAddress"
              name="emailAddress"
              value={`${formData.emailAddress}${emailName}`}
              styles={{ root: { width: '50%' } }}
            />
            <Label>
              Email: {emailName}@{companyDomainName}.yourdomain.com{' '}
              {loading && <> <Spinner className="" label="" /></>}
              {submitted && !loading && (
                <>
                  {emailValidity}
                </>
              )}
            </Label>
            <hr />

            {/* Third Section */}
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <TextField
                label="Mobile Phone"
                name="MobilePhone"
                value={formData.MobilePhone}
                onChange={handleChange}
                styles={{ root: { width: '50%' } }}
                type="tel"
              />
              <TextField
                label="Office Phone"
                name="OfficePhone"
                value={formData.OfficePhone}
                onChange={handleChange}
                styles={{ root: { width: '50%' } }}
                type="tel"
              />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Dropdown
                label="Department"
                name="Department"
                selectedKey={formData.Department}
                onChange={(event, option) => handleDropdownChange(event, option, 'Department')}
                options={[
                  { key: 'IT', text: 'IT' },
                  { key: 'Marketing', text: 'Marketing' },
                  { key: 'Sales', text: 'Sales' },
                  { key: 'HR', text: 'Human Resources' },
                  { key: 'Finance', text: 'Finance' },
                ]}
                required
styles={{ root: { width: '50%' } }}
              />
              <Dropdown
                label="Location"
                name="Location"
                selectedKey={formData.Location}
                onChange={(event, option) => handleDropdownChange(event, option, 'Location')}
                options={[
                  { key: 'Melbourne', text: 'Melbourne' },
                  { key: 'Brisbane', text: 'Brisbane' },
                ]}
                required
styles={{ root: { width: '50%' } }}
              />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Dropdown
                label="Role"
                name="Role"
                selectedKey={formData.Role}
                onChange={(event, option) => handleDropdownChange(event, option, 'Role')}
                options={roleOptions}
                required
styles={{ root: { width: '50%' } }}
              />
            </Stack>
            <TextField
              label="Title"
              name="Title"
              value={formData.Title}
              onChange={handleChange}
              styles={{ root: { width: '50%' } }}
            />
            <Checkbox label="Additional action" name='additionalAction' onChange={handleCheckboxChange}></Checkbox>
            <div className="page-bottom-buttons">
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <PrimaryButton type="submit" disabled={!isFormValid}>Save</PrimaryButton>
              </Stack>
            </div>
          </Stack>
        </form>
        <UserCustomLicence styles={{ margin: 20 }} Department={departmentTasks} AdditinalActions={AdditinalActions} />
      </div>
    </div>
  );
};

export default AddUser;
