import leftCaret from '../assets/images/tourPopupLeftCaret.svg'
import rightCaret from '../assets/images/tourPopupRightCaret.svg'


const caretClasses = {
  'top-left': '-left-[20px] top-[10px]',
  'top-right': '-right-[20px] top-[10px]',
  'left': '-left-[20px] top-1/2 transform -translate-y-1/2',
  'right': '-right-[20px] top-1/2 transform -translate-y-1/2',
  'bottom-left': '-left-[20px] bottom-[10px]',
  'bottom-right': '-right-[20px] bottom-[10px]',
  'bottom': '-rotate-90 -bottom-[20px] left-1/2 transform -translate-x-1/2',
  'bottom-bottom-left': '-rotate-90 -bottom-[20px] left-[20px]'
};

export default function TourPopup({ show, text, onNext, onPrev, className = "", caretDirection = 'top-left' }) {
  return (
    <div
      className={`absolute w-[23rem] pt-[1.75rem] px-[2rem] pb-[1rem] bg-microsoft rounded-[0.75rem] z-50 shadow-xl ${className}`}
      style={{
        transition: "opacity 0.4s ease, transform 0.3s ease",
        opacity: show ? 1 : 0,
        pointerEvents: show ? "auto" : "none",
      }}
    >
      {caretDirection?.includes('left') &&
        <img
          src={leftCaret}
          alt="carrot"
          className={`absolute w-[20px] h-auto ${caretClasses[caretDirection]}`}
        />
      }
      {(caretDirection === 'bottom' || caretDirection.includes('-bottom')) &&
        <img
          src={leftCaret}
          alt="carrot"
          className={`absolute w-[20px] h-auto ${caretClasses[caretDirection]}`}
        />
      }
      {caretDirection?.includes('right') &&
        <img
          src={rightCaret}
          alt="carrot"
          className={`absolute w-[20px] h-auto ${caretClasses[caretDirection]}`}
        />
      }

      <p className="text-white text-[15px]">
        {text}
      </p>
      <div className="mt-[1.25rem] flex justify-between">
        <div>
          {!!onPrev &&
            <p className="cursor-pointer text-white opacity-40 text-[12px]" onClick={onPrev}>
              Previous
            </p>
          }
        </div>

        {!!onNext &&
          <p className="cursor-pointer text-white opacity-40 text-[12px]" onClick={onNext}>
            Next
          </p>
        }
      </div>
    </div>
  );
}
