import React from 'react';

export const UserOpenedTimeGenerator = (action, UserEmail, UserFormFromSubmit) => {
  console.log(UserFormFromSubmit);

  // Retrieve users from sessionStorage or initialize an empty array
  const storedUsers = sessionStorage.getItem('users');
  const users = storedUsers ? JSON.parse(storedUsers) : [];

  // Initialize a variable to hold the modified user
  let modifiedUser = null;

  // Function to modify a specific user's properties
  const modifiedUsers = users.map(user => {
    if (user.GeneratedEmail === UserEmail) {
      const newOpenedTime = user[action]?.OpenedTime ?? Math.floor(new Date().getTime() / 1000);
      const updatedUser = {
        ...user,
        [action]: {
          OpenedTime: user[action]?.OpenedTime ?? newOpenedTime
        }
      };
      modifiedUser = updatedUser;
      return updatedUser;
    }
    return user;  // Return unchanged user object for other users
  });

  // If no user was modified, find the original user (to return the user even if no changes were made)
  if (!modifiedUser) {
    modifiedUser = users.find(user => user.GeneratedEmail === UserEmail) || null;
  }

  // Update sessionStorage with modified users if any user was modified
  if (modifiedUser) {
    sessionStorage.setItem('users', JSON.stringify(modifiedUsers));
  }

  // Return the modified user or null if the user wasn't found
  return modifiedUser;
};

export default UserOpenedTimeGenerator;
