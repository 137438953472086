import React from 'react';
import EmailNameCreator from './functions';
import { Spinner, SpinnerSize, Label, Icon } from '@fluentui/react';

import { initializeIcons } from '@fluentui/react/lib/Icons';

initializeIcons();
export const CheckValidityAndExportTick = ({ FirstName, LastName, PreferredFirstName, PreferredLastName }) => {
    const storedUsers = sessionStorage.getItem('users');
    const users = storedUsers ? JSON.parse(storedUsers) : [];
    console.log("afdsjkl;fdsahkhkjlsfdhljksdflhjkchjk")
    var validity = true;
    var StateImage = '';
    var ClassName = '';

    const emailName = EmailNameCreator({
        FirstName,
        LastName,
        PreferredFirstName: PreferredFirstName || '', // Ensure it defaults to '' if undefined
        PreferredLastName: PreferredLastName || '' // Ensure it defaults to '' if undefined
    });

    users.forEach(user => {
        if (EmailNameCreator({
            FirstName: user.FirstName,
            LastName: user.LastName,
            PreferredFirstName: user.PreferredFirstName,
            PreferredLastName: user.PreferredLastName
        }) === emailName) {
            validity = false;
        }
    });

    if (validity) {
        StateImage = "SkypeCircleCheck";
        ClassName ="valid-icon"

    } else {
        StateImage = "SkypeCircleMinus";
        ClassName ="invalid-icon"
    }

    return (
        // <Spinner label="" />
            <Icon iconName={StateImage} className={ClassName}/>
            // <img src={StateImage} alt={validity ? 'Valid' : 'Not Valid'} />

    );
};

export default CheckValidityAndExportTick;