import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import AddUser from './components/AddUser';
import OnboarderOffboarder from './components/OnboarderOffboarder';
import NumberOfUnapproved from './utils/GetNumberUnapporovedUsers'
import UserOffBoarder from './components/UserOffBoarder';
import UserApprover from './components/UserApprover';
import UserInfo from './components/UserInfo';
import CalendarPage from './components/FakeCalender';
import ChatPage from './components/Chat';
import ModelFinisher from './components/ModelFinisher';
import useTourStore from "./store/tourStore";
import TourPopup from "./components/TourPopup";

function App() {
  const referenceRef = useRef(null);
  const arrowElementRef = useRef(null);
  const navigate = useNavigate();
  const {closeTour, currentTourStep, nextTourStep} = useTourStore();
  const state = useTourStore();
  const [unapprovedUsers, setUnapprovedUsers] = useState(NumberOfUnapproved());

  console.log('-----------------------------')
  console.log('-----------STATE------', state)
  console.log('-----------------------------')

  const handleActivityOnClick = () => {
    if (currentTourStep === 1 || currentTourStep === 9) {
      nextTourStep()
    }

    navigate('/user-approver');
  };
  const MailPageRedirect = () => {


    navigate('/');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setUnapprovedUsers(NumberOfUnapproved());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const location = useLocation();
  const locationPathname = location.pathname

  return (
    <div className="app-layout">
      <ModelFinisher
        isOpen={currentTourStep === 14}
        onClose={() => {
          navigate('/')
          closeTour();
          sessionStorage.setItem('users', []);
          window.location.reload();
        }}
      />
      <div className="sidebar">
        <TourPopup
          show={currentTourStep === 1 || currentTourStep === 9}
          text="Approving Manager Notified - click here"
          className="!fixed top-[5px] left-[70px]"
          onNext={handleActivityOnClick}
        />
        <TourPopup
          caretDirection="left"
          show={(currentTourStep === 4 || currentTourStep === 12) && locationPathname === "/user-approver"}
          text="Return to Teams"
          className="!fixed top-[95px] left-[70px]"
          onNext={() => navigate('/')}
        />
        {/* <a onClick={handleActivityOnClick}> */}
        <div className="sidebar-item sidebar-item-included" onClick={handleActivityOnClick} aria-label="Activity">
          <svg fill="var(--colorNeutralForeground3)" className="___12fm75w f1w7gpdv fez10in fg4l7m0  sidebar-icon-svg" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2a7.5 7.5 0 0 1 7.5 7.25v4.35l1.38 3.15a1.25 1.25 0 0 1-1.15 1.75H15a3 3 0 0 1-6 .18v-.18H4.27a1.25 1.25 0 0 1-1.14-1.75L4.5 13.6V9.5C4.5 5.35 7.85 2 12 2Zm1.5 16.5h-3a1.5 1.5 0 0 0 3 .15v-.15ZM12 3.5c-3.32 0-6 2.67-6 6v4.4L4.66 17h14.7L18 13.9V9.29a5.99 5.99 0 0 0-6-5.78Z" fill="var(--colorNeutralForeground3)"></path>
          </svg>
          {unapprovedUsers !== 0 && <div className='notification-activity'>{unapprovedUsers}</div>}
          <span className="sidebar-title-text">Activity</span>

        </div>
        {/* </a> */}
        <div className="sidebar-item not-included-sidebar-item" aria-label="Chat">
          <svg fill="var(--colorNeutralForeground3)" className="___12fm75w f1w7gpdv fez10in fg4l7m0 sidebar-icon-svg" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2a10 10 0 1 1-4.59 18.89L3.6 21.96a1.25 1.25 0 0 1-1.54-1.54l1.06-3.83A10 10 0 0 1 12 2Zm0 1.5a8.5 8.5 0 0 0-7.43 12.64l.15.27-1.1 3.98 3.98-1.11.27.15A8.5 8.5 0 1 0 12 3.5ZM8.75 13h4.5a.75.75 0 0 1 .1 1.5h-4.6a.75.75 0 0 1-.1-1.5h4.6-4.5Zm0-3.5h6.5a.75.75 0 0 1 .1 1.5h-6.6a.75.75 0 0 1-.1-1.5h6.6-6.5Z" fill="var(--colorNeutralForeground3)"></path>
          </svg>
          <span className="sidebar-title-text">Chat</span>
        </div>
        <div class="not-included-tooltip"><h3>Not in demo</h3></div>

        {/* <a href="/"> */}
        <div className="sidebar-item sidebar-item-included" onClick={MailPageRedirect}   aria-label="Teams">
          <svg fill="var(--colorNeutralForeground3BrandSelected)" className="___12fm75w f1w7gpdv fez10in fg4l7m0 sidebar-icon-svg" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.75 10c.97 0 1.75.78 1.75 1.75v4.75a4.5 4.5 0 0 1-9 0v-4.75c0-.97.79-1.75 1.75-1.75h5.5Zm-7.62 0c-.35.42-.57.95-.62 1.53v4.97c0 .85.18 1.65.52 2.36A4 4 0 0 1 2 15v-3.24c0-.92.7-1.67 1.6-1.74l.15-.01h3.38Zm9.74 0h3.38c.97 0 1.75.78 1.75 1.75V15a4 4 0 0 1-5.03 3.87c.3-.63.48-1.32.53-2.06v-5.06c0-.67-.23-1.28-.63-1.75ZM12 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm6.5 1a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm-13 0a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Z" fill="var(--colorNeutralForeground3BrandSelected)"></path>
          </svg>
          <span className="sidebar-title-text">Teams</span>

        </div>
        <div class="not-included-tooltip"><h3>Not in demo</h3></div>

        {/* </a> */}
        {/* <a href="/calender"> */}
        <div className="sidebar-item not-included-sidebar-item" aria-label="Calendar">
          <svg fill="var(--colorNeutralForeground3)" className="___12fm75w f1w7gpdv fez10in fg4l7m0 sidebar-icon-svg" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.75 3C19.55 3 21 4.46 21 6.25v11.5c0 1.8-1.46 3.25-3.25 3.25H6.25A3.25 3.25 0 0 1 3 17.75V6.25C3 4.45 4.46 3 6.25 3h11.5Zm1.75 5.5h-15v9.25c0 .97.78 1.75 1.75 1.75h11.5c.97 0 1.75-.78 1.75-1.75V8.5Zm-11.75 6a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5Zm4.25 0a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5Zm-4.25-4a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5Zm4.25 0a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5Zm4.25 0a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5Zm1.5-6H6.25c-.97 0-1.75.78-1.75 1.75V7h15v-.75c0-.97-.78-1.75-1.75-1.75Z" fill="var(--colorNeutralForeground3)"></path>
          </svg>
          <span className="sidebar-title-text">Calendar</span>

        </div>
        <div class="not-included-tooltip"><h3>Not in demo</h3></div>

        <div className="sidebar-item not-included-sidebar-item" aria-label="Calls">
          <svg fill="var(--colorNeutralForeground3)" class="___12fm75w f1w7gpdv fez10in fg4l7m0 sidebar-icon-svg" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m7.06 2.42 1.16-.35c1.33-.4 2.74.24 3.3 1.5l.9 2a2.75 2.75 0 0 1-.63 3.15L10.3 10.1a.25.25 0 0 0-.08.16c-.04.4.23 1.17.85 2.24.45.78.86 1.33 1.2 1.64.25.21.38.26.44.24l2-.61a2.75 2.75 0 0 1 3.04 1.02l1.28 1.77c.8 1.12.66 2.66-.34 3.6l-.88.85a3.75 3.75 0 0 1-3.59.89c-2.75-.77-5.22-3.1-7.44-6.93-2.21-3.83-2.99-7.14-2.27-9.91a3.75 3.75 0 0 1 2.55-2.65Zm.43 1.43c-.75.23-1.33.83-1.53 1.6-.6 2.33.09 5.26 2.12 8.78 2.04 3.53 4.23 5.59 6.54 6.23.76.21 1.58.01 2.15-.53l.9-.84c.45-.43.51-1.13.15-1.64l-1.29-1.77a1.25 1.25 0 0 0-1.37-.47l-2.02.62c-1.17.35-2.23-.6-3.37-2.57C9 11.93 8.64 10.9 8.73 10.1c.05-.42.24-.8.55-1.09l1.5-1.39c.38-.37.5-.94.28-1.43l-.9-2c-.26-.58-.9-.87-1.5-.69l-1.17.35Z" fill="var(--colorNeutralForeground3)"></path></svg>
          <span className="sidebar-title-text">Calls</span>

        </div>
        <div class="not-included-tooltip"><h3>Not in demo</h3></div>
        <div className="sidebar-item not-included-sidebar-item" style={{paddingTop:-5,paddingBottom:10,height:35}} aria-label="Chat">
          <svg fill="var(--colorNeutralForeground3)" className="___12fm75w f1w7gpdv fez10in fg4l7m0 sidebar-icon-svg" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.75 12a1.75 1.75 0 1 1-3.5 0 1.75 1.75 0 0 1 3.5 0Zm6 0a1.75 1.75 0 1 1-3.5 0 1.75 1.75 0 0 1 3.5 0ZM18 13.75a1.75 1.75 0 1 0 0-3.5 1.75 1.75 0 0 0 0 3.5Z" fill="var(--colorNeutralForeground3)"></path>          </svg>
        </div>
        <div className="sidebar-item not-included-sidebar-item" aria-label="AddApp">
          <svg fill="var(--colorNeutralForeground3)" class="___12fm75w f1w7gpdv fez10in fg4l7m0 sidebar-icon-svg" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 7c.41 0 .75.34.75.75v3.5h3.5a.75.75 0 0 1 0 1.5h-3.5v3.5a.75.75 0 0 1-1.5 0v-3.5h-3.5a.75.75 0 0 1 0-1.5h3.5v-3.5c0-.41.34-.75.75-.75Zm-9-.75C3 4.45 4.46 3 6.25 3h11.5C19.55 3 21 4.46 21 6.25v11.5c0 1.8-1.46 3.25-3.25 3.25H6.25A3.25 3.25 0 0 1 3 17.75V6.25ZM6.25 4.5c-.97 0-1.75.78-1.75 1.75v11.5c0 .97.78 1.75 1.75 1.75h11.5c.97 0 1.75-.78 1.75-1.75V6.25c0-.97-.78-1.75-1.75-1.75H6.25Z" fill="var(--colorNeutralForeground3)"></path></svg>
          <span className="sidebar-title-text">App</span>

        </div>
        <div class="not-included-tooltip"><h3>Not in demo</h3></div>


        {/* </a> */}

      </div>

      <div className="app-layout-area">
        <div className="slot-measurer">
          <div className="channel-list-container" role="region" aria-labelledby="team-header-title">
            <div className="channel-list-header">
              <h1 id="team-header-title">Teams</h1>
              <div className="channel-list-options">
                <button aria-label="More channel options">
                  <span className="icon">
                    <svg className="fui-Icon-regular ___12fm75w f1w7gpdv fez10in fg4l7m0" fill="currentColor" aria-hidden="true" width="1em" height="1em" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.25 10a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0Zm5 0a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0ZM15 11.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z" fill="currentColor"></path>
                    </svg>
                  </span>
                </button>
                <button aria-label="Filter">
                  <span className="icon">
                    <svg className="fui-Icon-regular ___12fm75w f1w7gpdv fez10in fg4l7m0" fill="currentColor" aria-hidden="true" width="1em" height="1em" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.5 13h5a.5.5 0 0 1 .09 1H7.5a.5.5 0 0 1-.09-1h5.09-5Zm-2-4h9a.5.5 0 0 1 .09 1H5.5a.5.5 0 0 1-.09-1h9.09-9Zm-2-4h13a.5.5 0 0 1 .09 1H3.5a.5.5 0 0 1-.09-1H16.5h-13Z" fill="currentColor"></path>
                    </svg>
                  </span>
                </button>
                <button aria-label="Create and join teams and channels">
                  <span className="icon">
                    <svg className="fui-Icon-regular ___12fm75w f1w7gpdv fez10in fg4l7m0" fill="currentColor" aria-hidden="true" width="1em" height="1em" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 2.5a.5.5 0 0 0-1 0V9H2.5a.5.5 0 0 0 0 1H9v6.5a.5.5 0 0 0 1 0V10h6.5a.5.5 0 0 0 0-1H10V2.5Z" fill="currentColor"></path>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
            <hr />
            <div className="tree" role="tree" aria-labelledby="team-header-title">
              <div className="tree-item" role="treeitem" aria-expanded="true" tabIndex="0" aria-setsize="1" aria-posinset="1" aria-level="1">
                <span className="tree-title">Your teams</span>
              </div>
              <div className="tree-item" role="treeitem" aria-expanded="true" tabIndex="-1" aria-setsize="1" aria-posinset="1" aria-level="2">
                <div style={{ color: "black", width: 30, height: 30,marginRight:10, backgroundColor: '#fff', borderRadius: 3, display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', fontSize: 16 }}><img className='teams-tab-image' src='./HoundID_Icon.svg'></img></div>
                <span className="tree-title" style={{fontSize:12}}>HoundID</span>
                <button aria-label="More options" className="more-options">
                  <span className="icon">&hellip;</span>
                </button>
              </div>
              <div className='teams-channels-sidebar'>
                <span style={{fontSize:14, fontWeight:"bold"}}>General</span>
              </div>
              {/* <div className='teams-channels-sidebar'>
                <span>TrilogyID</span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="teams-application-pane">
        <Routes>
          <Route path="/" ref={referenceRef}  element={<OnboarderOffboarder />} />
          <Route path="/add-user" element={<AddUser />} />
          <Route path="/user-approver" element={<UserApprover />} />
          <Route path="/user-info" element={<UserInfo />} />
          <Route path="/calender" element={<CalendarPage />} />
          <Route path="/chat" element={<ChatPage />} />
          <Route path="/offboard-user" element={<UserOffBoarder />} />
        </Routes>
      </div>
    </div>

  );
}

export default App;
