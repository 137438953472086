import React from 'react';

export const UserOffboardSetter = (action, UserEmail,UserFormFromSubmit) => {
  console.log(action)
  // Retrieve users from sessionStorage or initialize empty array
  const storedUsers = sessionStorage.getItem('users');
  const users = storedUsers ? JSON.parse(storedUsers) : [];
  
  // Function to modify a specific user's properties
  const modifiedUsers = users.map(user => {
    if (user.GeneratedEmail === UserEmail) {
      return {
        ...user,
        UseroffboarderAdditinalActions: action,  // Modify the `Status` property based on `action` parameter
      };
    }
    return user;  // Return unchanged user object for other users
  });

  sessionStorage.setItem('users', JSON.stringify(modifiedUsers)); // Update sessionStorage with modified users

  // Prepare and return result message indicating the change made
  const result = `${UserEmail} status changed to ${action}`;
  return result;
};

export default UserOffboardSetter;
