import React, {useEffect, useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { ComboBox, DefaultButton, DatePicker, DayOfWeek, TextField, Checkbox, Stack, Text } from '@fluentui/react';
import { UserModifier } from '../utils/UserModifier';
import { UserOffboardSetter } from '../utils/UserOffboardSetter'
import dropdownStyles from '../styles/DropdownStyle';
import Navbar from './Navbar';
import TeamsTab from './TeamsTab';
import UserCustomLicence from './UserCustomLicence';
import './../App.css';
import useTourStore from "../store/tourStore";
import TourPopup from "./TourPopup";


const UserOffBoarder = () => {
  const navigate = useNavigate();
  const storedUsers = sessionStorage.getItem('users');
  const users = storedUsers ? JSON.parse(storedUsers) : [];
  const [formData, setFormData] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [terminationDate, setTerminationDate] = useState(null);
  const [customMessage, setCustomMessage] = useState('');
  const [forwardingEmail, setForwardingEmail] = useState('');
  const [overrideMessage, setOverrideMessage] = useState(false);
  const [hasAdditionalActions, setHasAdditionalActions] = useState(false);
  const {currentTourStep, setTourState, nextTourStep, previousTourStep} = useTourStore();
  const scrollRef = useRef(null);
  const topScrollRef = useRef(null);

  const handleSubmit = (e) => {
    e?.preventDefault(); // Prevent default form submission
    console.log(formData)
    console.log(selectedUser.GeneratedEmail)
    const users = storedUsers ? JSON.parse(storedUsers) : [];

    // console.log(selectedUser)
    UserModifier("OffboarderedWaitingForApproval",selectedUser.GeneratedEmail)

    // Validate required fields
    if (!terminationDate) {
      alert('Please select a termination date.');
      return;
    }

    // Call UserModifier function with appropriate parameters
    if (selectedUser) {
      const formData = {
        userId: selectedUser.userId, // Adjust with actual user ID field
        forwardingEmail,
        terminationDate,
        customMessage,
        hasAdditionalActions
      };
      UserOffboardSetter(hasAdditionalActions, selectedUser.GeneratedEmail)
      console.log(selectedUser)
    } else {
      alert('Please select a user.');
    }
    navigate('/')
    nextTourStep();
  };

  const handleCheckboxChange = (ev, isChecked) => {
    setHasAdditionalActions(isChecked);
  };

  const pageCloser = () => {
    console.log("fklads")
    navigate('/')
  }

  const handleComboBoxChange = (event, option) => {
    if (option) {
      const user = users.find(user => user.GeneratedEmail === option.text);
      setSelectedUser(user);
      if (currentTourStep === 6) {
        nextTourStep();

      }
      setForwardingEmail(user ? `admin@example.yourdomain.com` : '');
    }
  };

  const handleOffboardUser = () => {
    navigate('/offboard-user');
  };

  console.log(users)
  const approvedUsers = users.filter(user => user.Status === 'Approved');
  console.log(approvedUsers)

  const userOptions = approvedUsers.map(user => ({
    key: user.GeneratedEmail,
    text: user.GeneratedEmail,
  }));

  useEffect(() => {
    if (terminationDate && scrollRef.current) {
      if (currentTourStep === 7) {
        nextTourStep()
      }
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      topScrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [terminationDate]);

  return (
    <div className="">
      <TeamsTab />
      <div className="requests-container">
        <Navbar />
        <div>
          <div ref={topScrollRef} className="offboarder-controls relative">
            <TourPopup
              caretDirection="left"
              show={currentTourStep === 6}
              text="Select user"
              className="-top-[24px] left-[calc(50%+20px)]"
              onNext={() => {
                handleComboBoxChange(null, userOptions?.[userOptions?.length - 1])
              }}
              onPrev={() => {
                setTourState({currentTourStep: 4.1});
                navigate('/')
              }}
            />
            <TourPopup
              caretDirection="bottom"
              show={currentTourStep === 7}
              text="Termination date required"
              className="top-[90px] left-1/2 transform -translate-x-1/2"
              onPrev={() => {
                previousTourStep()
                setSelectedUser(null)
              }}
              onNext={() => {
                setTerminationDate(new Date())
              }}
            />
            <ComboBox
              placeholder="Search by email"
              options={userOptions}
              onChange={handleComboBoxChange}
              selectedKey={selectedUser?.GeneratedEmail ?? 0}
              styles={dropdownStyles}
            />
          </div>
          <form onSubmit={handleSubmit}>
            {selectedUser && (
              <div style={{ marginTop: '20px' }}>
                <Stack tokens={{ childrenGap: 15 }}>
                  <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <TextField label="User" value={selectedUser.GeneratedEmail} disabled />
                  </Stack>

                  <DatePicker
                    label="Termination Date"
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    value={terminationDate}
                    onSelectDate={date => setTerminationDate(date)}
                    firstDayOfWeek={DayOfWeek.Sunday}
                  />

                  {!terminationDate && (
                    <Text variant="small" style={{ color: 'red' }}>
                      Termination Date is required
                    </Text>
                  )}

                  <TextField
                    label="Access to OneDrive will be granted to:"
                    value="Sandbox Admin"
                    disabled
                  />

                  <Checkbox
                    label="Override out of office message"
                    checked={overrideMessage}
                    onChange={(_, checked) => setOverrideMessage(checked)}
                  />

                  <TextField
                    label="Custom message for out of office offboarding"
                    multiline
                    rows={3}
                    placeholder="Default out of office"
                    value={customMessage}
                    onChange={(_, newValue) => setCustomMessage(newValue)}
                    disabled={!overrideMessage}
                  />

                  <Checkbox label="Send to Internal" disabled={!overrideMessage} />
                  <Checkbox label="Send to External" disabled={!overrideMessage} />

                  <TextField
                    label="Forwarding Email"
                    value={forwardingEmail}
                    disabled
                  />

                  <Checkbox
                    label="Additional actions"
                    checked={hasAdditionalActions}
                    onChange={handleCheckboxChange}
                  />
                  {/* <UserCustomLicence styles={{ margin: 20 }} Department={departmentTasks} AdditinalActions={AdditinalActions} /> */}
                  <UserCustomLicence AdditinalActionsOffboard={hasAdditionalActions} hasAdditionalActions={hasAdditionalActions} AdditinalActionsOffboardOnly={true} />

                  <div ref={scrollRef} className="page-bottom-buttons user-offboard-buttons relative">
                    {/* <div className="onboarder-controls onboarder-onboarding"> */}
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                      <TourPopup
                        caretDirection="bottom-bottom-left"
                        show={currentTourStep === 8}
                        text="Click save"
                        className="bottom-[50px] left-[25px]"
                        onNext={handleSubmit}
                        onPrev={() => {
                          setTerminationDate(null)
                          previousTourStep()
                        }}
                      />
                      <DefaultButton className='onboarder-user-screen' type="submit" text="Save" />
                      <DefaultButton className='onboarder-user-screen' onClick={pageCloser}  text="Close" />
                    </Stack>
                  </div>
                </Stack>
              </div>
            )}
          </form>
          {/* <div className="page-bottom-buttons"> */}
          <div className="onboarder-controls onboarder-onboarding">
            {!selectedUser && (<DefaultButton onClick={pageCloser} className='onboarder-user-screen' text="Close" />)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserOffBoarder;
