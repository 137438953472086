import React, { useEffect, useState } from 'react';
import { Spinner, SpinnerSize, Label, Icon } from '@fluentui/react';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import {CleanUserStatus} from '../utils/CleanUserStatus'

initializeIcons();

// List of status steps and their respective labels
const statusSteps = [
  { label: 'Approving' },
  { label: 'Adding user into AD' },
  { label: 'Enterprise apps assignment' },
  { label: 'Adding user into AD groups' },
  { label: 'Additional license getting' },
  { label: 'Office 365 licenses assignment' },
  { label: 'User Added Into Shared Mailboxes' },
  { label: 'Custom actions sending' },
  { label: 'Completion' },
];

const ApprovalStatus = ({ ApprovalTime, stepDurations, status}) => {
  const [currentStep, setCurrentStep] = useState(0);

  const calculateCurrentStep = () => {
    if (ApprovalTime && stepDurations && stepDurations.length === statusSteps.length) {
      const startTime = ApprovalTime;
      const now = Math.floor(Date.now() / 1000);
      const elapsedTime = now - startTime;

      let accumulatedTime = 0;
      for (let i = 0; i < stepDurations.length; i++) {
        accumulatedTime += stepDurations[i];
        if (elapsedTime < accumulatedTime) {
          return i;
        }
      }
      return statusSteps.length - 1; // All steps completed
    }
    return 0;
  };
  const returnedPage = "Onboard"
  const userStatus = status

  const statusMessage = CleanUserStatus({ userStatus, returnedPage });

  useEffect(() => {
    // Initial calculation of current step
    setCurrentStep(calculateCurrentStep());

    // Set interval to update current step every second
    const interval = setInterval(() => {
      setCurrentStep(calculateCurrentStep());
    }, 1000); // Update every second

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [ApprovalTime, stepDurations]);

  return (
    <div class="requests-container">
    <div className="approval-status">
      <Label className="ms-Label newUserStatus">
        Status: {status}
      </Label>
      <div>
      {
        
      statusSteps.map((step, index) => (
        <div key={index} className="status-item">
          {
          status === "Unapproved" ? (
            index === 0 ? (
            <Spinner size={SpinnerSize.xSmall} className="status-item" />
            ) : (
                <Icon iconName="LocationCircle" className="status-completed-icon" />
            )
          ) : (
          index < currentStep ? (
            <Icon iconName="SkypeCircleCheck" className="status-completed-icon" />
          ) : index > currentStep && currentStep < statusSteps.length ? (
            <Spinner size={SpinnerSize.xSmall} className="status-spinner" />
          ) : currentStep <= statusSteps.length ? (
            <Icon iconName="SkypeCircleCheck" className="status-completed-icon" />
          ) : (
            <Icon iconName="SkypeCircleCheck" className="status-static-icon" />
          )
        )}
          <Label className="ms-Label status-label">{step.label}</Label>
        </div>
      ))
      
      }
    </div>
      <br />
    </div>
    </div>
  );
};

export default ApprovalStatus;
