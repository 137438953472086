import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useTourStore = create(
  persist(
    (set) => ({
      currentTourStep: 0,
      showTour: false,

      setTourState: (payload) => set((state) => {
        const newState = {};

        Object.entries(payload).forEach(([key, value]) => {
          newState[key] = value;
        });

        return newState;
      }),
      nextTourStep: () => set((state) => ({
        currentTourStep: state.currentTourStep + 1
      })),
      previousTourStep: () => set((state) => ({
        currentTourStep: Math.max(state.currentTourStep - 1, 0)
      })),
      closeTour: () => set((state) => {
        return {
          showTour: false,
          currentTourStep: 0,
        };
      }),
    }),
    {
      name: 'tour-storage',
      getStorage: () => localStorage,
    }
  )
);

export default useTourStore;