import React from 'react';



export const EmailNameCreator = ({ FirstName, LastName, PreferredFirstName = '', PreferredLastName = ''}) => {
    // Logging the inputs for debugging purposes
    // console.log("emailnamecreator")
    // console.log(FirstName)
    // console.log(LastName)
    // console.log(PreferredFirstName)
    // console.log(PreferredLastName)
    // console.log("_____________")


 // Determine which first name to use
    // Use FirstName if provided, otherwise use PreferredFirstName
    const ReturnedFirstName = PreferredFirstName.length > 0 ? PreferredFirstName : FirstName;

    // Determine which last name to use
    // Use LastName if provided, otherwise use PreferredLastName
    const ReturnedLastName = PreferredLastName.length > 0 ? PreferredLastName : LastName;

    // Concatenate the selected first and last names to create the email name
    const returnedEmailName = `${ReturnedFirstName}${ReturnedLastName}`;
    // Return the final email name
    return returnedEmailName;
};

export default EmailNameCreator

// Named export for EmailNameCreator
// export { EmailNameCreator };

// Default export for CheckValidityAndExportTick
// export default CheckValidityAndExportTick;
