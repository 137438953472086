import React, { useState } from 'react';
import Navbar from './Navbar';
import { Spinner, SpinnerSize, Label,Icon, FocusZoneTabbableElements } from '@fluentui/react';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import {useLocation, useNavigate} from 'react-router-dom'; // Import useLocation hook from react-router-dom
import {EmailNameCreator } from './../utils/functions';
import ApprovalStatus from './ApprovalStatus'
import OffLoadStatus from './OffloadStatus';
import TeamsTab from './TeamsTab';
import { TextField, DatePicker, Dropdown, Checkbox, Stack } from '@fluentui/react';
import UserCustomLicence from './UserCustomLicence';
import {UserOpenedTimeGenerator} from '../utils/UserOpenedTimeGenerator'
import useTourStore from "../store/tourStore";
import TourPopup from "./TourPopup";

initializeIcons();

function UserInfo() {
  const navigate = useNavigate();
  const storedUsers = sessionStorage.getItem('users');
  const [activeTab, setActiveTab] = useState('onboarding');
  const {currentTourStep, setTourState} = useTourStore();
  var NumberOfUnapprovedUsers = 0
  // Parse the JSON string into a JavaScript array
  const approvals = storedUsers ? JSON.parse(storedUsers) : [];

  console.log(approvals)
  const location = useLocation();
  const { state }  = location;

  if (!state) {
    // Handle the case where state is not defined (optional)
    return <div>Loading...</div>;
  }

  console.log(state)

  // Extract individual variables from state
  // Destructure individual variables from state
  const {
    Name,
    Email,
    Status,
    CreatedBy,
    CreateDate,
    StartDate,
    UserName,
    OffLoadstatus,
    Department,
    Infotarget,
    FirstName,
    LastName,
    PrefferedLastName,
    PrefferedFirstName
  } = state;
  const email = EmailNameCreator({
    FirstName: FirstName,
    LastName: LastName,
    PreferredFirstName: PrefferedFirstName,
    PreferredLastName: PrefferedLastName,
  });

  var departmentTasks = Department
  var  expireTime = 0
  var  ApprovedOpenTime = 0
  var  offLoadExpiretime = 0
  var OffboarderedWaitingForApproval = 0
  // Find the user object where GeneratedEmail matches emailToFind
  const foundUser = approvals.find(user => user.GeneratedEmail === email);
  const options = foundUser.Department
  if (foundUser) {
    const userAfterGenerator = UserOpenedTimeGenerator(foundUser.Status,foundUser.GeneratedEmail)
    ApprovedOpenTime = userAfterGenerator?.Approved?.OpenedTime;
    departmentTasks = foundUser.Department
    OffboarderedWaitingForApproval = foundUser.OffboarderedWaitingForApproval ? foundUser.OffboarderedWaitingForApproval.time : null
  } else {

  }
  const stepDurations = [1, 2, 3, 4, 5.5, 6, 8, 9 , 9.5]; // Custom durations for each step in seconds
  const stepOffboardDurations = [1, 2]; // Custom durations for each step in seconds

  const status = foundUser.Status;
  const AdditinalActions = foundUser.additionalActions
  const AdditinalActionsOffboard = foundUser.UseroffboarderAdditinalActions

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setTourState({currentTourStep: 4.1})
    navigate('/');
  };

  return(
    <div className="relative">
      <TourPopup
        caretDirection="right"
        show={currentTourStep === 4 }
        text="Return here"
        className="top-[105px] right-[135px]"
        onNext={() => {
          handleTabClick('onboarding')
        }}
        onPrev={() => navigate('/')}
      />
      <TeamsTab/>
      <div className="requests-container">
        <Navbar activeTab={activeTab} onTabClick={handleTabClick}/>
        {Infotarget === "Onboarding" ? (
          <ApprovalStatus ApprovalTime={ApprovedOpenTime} stepDurations={stepDurations} status={status} />
        ) : Infotarget === "Offboarding" ? (
          <OffLoadStatus ApprovalTime={OffboarderedWaitingForApproval} stepDurations={stepOffboardDurations} status={status} />
        ) : (
          <p></p>
        )
        }


        {/* <ApprovalStatus ApprovalTime={expireTime} stepDurations={stepDurations} status={status} /> */}
        <div>
          {/* <div className="row"> */}
          <Stack tokens={{ childrenGap: 20 }}>

            <Dropdown
              label="Company"
              name="Company"
              selectedKey={foundUser.Company}
              // onChange={handleChange}
              options={[{ key: 'example', text: 'Example (@example.yourdomain.com)' },
                { key: 'hybrid', text: 'Hybrid (@hybrid.yourdomain.com)' }]}
              required
              styles={{ root: { width: '50%' } }}

            />
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DatePicker
                required
                label="Start Date"
                value={new Date(foundUser.StartDate)}
                styles={{ root: { width: '50%' } }}

              />
              <DatePicker
                label="End Date"
                value={new Date(foundUser.EndDate)}
                styles={{ root: { width: '50%' } }}


              />
            </Stack>
            {/* <Checkbox label="Is Hybrid User" /> */}
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <TextField
                label="First Name"
                value={foundUser.FirstName}
                styles={{ root: { width: '50%' } }}


              />
              <TextField
                label="Last Name"
                value={foundUser.LastName}
                styles={{ root: { width: '50%' } }}


              />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <TextField
                label="Preferred First Name"
                value={foundUser.PreferredFirstName}
                styles={{ root: { width: '50%' } }}

              />
              <TextField
                label="Preferred Last Name"
                value={foundUser.PreferredLastName}
                styles={{ root: { width: '50%' } }}


              />
            </Stack>
            <TextField
              label="Email"
              value={`${foundUser.GeneratedEmail}@${foundUser.Company}.yourdomain.com`}
              styles={{ root: { width: "100%" } }}

            />
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <TextField
                label="Mobile phone"
                value={foundUser.MobilePhone}
                styles={{ root: { width: '50%' } }}


              />
              <TextField
                label="Office phone"
                value={foundUser.OfficePhone}
                styles={{ root: { width: '50%' } }}


              />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <TextField
                label="Role"
                value={foundUser.Role}
                styles={{ root: { width: '50%' } }}


              />
              <TextField
                label="Department"
                value={foundUser.Department}
                styles={{ root: { width: '50%' } }}


              />
            </Stack>
            <TextField
              label="Location"
              value={foundUser.Location}
              styles={{ root: { width: '50%' } }}


            />
          </Stack>

          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <TextField
              label="Title"
              name="Title"
              value={foundUser.Title}
              styles={{ root: { width: '50%' } }}
              // type="tel"
            />
          </Stack>

          <UserCustomLicence Department={departmentTasks} AdditinalActions={AdditinalActions} AdditinalActionsOffboard={AdditinalActionsOffboard} AdditionalActionsOffboardOnly={AdditinalActionsOffboard}/>

        </div>
      </div>
    </div>

  );
};
export default UserInfo;


