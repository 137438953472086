import * as React from 'react';
import {
  Fabric,
  MessageBar,
  MessageBarType,
  Text,
  Stack,
  IconButton,
  TextField,
  Modal,
} from '@fluentui/react';

const mockMessages = [
  {
    key: 1,
    message: 'Hey there!',
    senderName: 'John Doe',
    timestamp: new Date(),
  },
  {
    key: 2,
    message: 'Hi John!',
    senderName: 'Jane Smith',
    timestamp: new Date(),
  },
  // Add more messages as needed
];

const ChatPage = () => {
  const handleClose = () => {
    console.log('Modal closed');
    // Navigate to another page
  };

  return (
    <Fabric>
    <div>

      <Modal
        isOpen={true}
        onDismiss={handleClose}
        isBlocking={false}
      >
              <a href="/">
        <div className="main-99 ms-Stack css-118 ms-Modal-scrollableContent scrollableContent-100">
          <div>
            <button onClick={handleClose}>
              Close
            </button>
          </div>
          <p>Click to return to HoundID Demo</p>
        </div>
</a>
      </Modal>
      <Stack
        horizontal
        styles={{
          root: {
            height: '100vh',
            padding: 20,
          },
        }}
      >
        <Stack
          styles={{
            root: {
              width: '20%',
              backgroundColor: '#f3f2f1',
              padding: 20,
              boxShadow: '0 0 10px rgba(0,0,0,0.1)',
              overflowY: 'auto',
            },
          }}
        >
          <Text variant="xxLarge">Teams</Text>
          <TextField placeholder="Search chats" styles={{ root: { marginBottom: 10 } }} />
          <Stack>
            {mockMessages.map((msg) => (
              <MessageBar
                key={msg.key}
                messageBarType={MessageBarType.success}
                isMultiline={false}
              >
                <Text>{msg.senderName}: {msg.message}</Text>
              </MessageBar>
            ))}
          </Stack>
        </Stack>
        <Stack
          styles={{
            root: {
              width: '80%',
              padding: 20,
              boxShadow: '0 0 10px rgba(0,0,0,0.1)',
              overflowY: 'auto',
            },
          }}
        >
          <Stack horizontal verticalAlign="center">
            <Text variant="medium" styles={{ root: { marginLeft: 10 } }}>
              Jane Smith
            </Text>
            <IconButton iconProps={{ iconName: 'Video' }} title="Video call" />
            <IconButton iconProps={{ iconName: 'Phone' }} title="End call" />
          </Stack>
          <Stack>
            {mockMessages.map((msg) => (
              <MessageBar
                key={msg.key}
                messageBarType={MessageBarType.success}
                isMultiline={false}
              >
                <Text>{msg.senderName}: {msg.message}</Text>
              </MessageBar>
            ))}
          </Stack>
          <TextField placeholder="Type a message" styles={{ root: { marginTop: 20 } }} />
        </Stack>
      </Stack>
    </div>
    </Fabric>
  );
};

export default ChatPage;
