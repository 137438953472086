// src/positionArrow.js

export function updateArrowPosition() {
    const referenceElement = document.querySelector('.app-layout-area');
    const arrowElement = document.querySelector('.arrow-right');
  
    if (referenceElement && arrowElement) {
      const referenceRect = referenceElement.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      
      // Calculate the new top position based on the reference element's position
      const newTopPosition = referenceRect.top + scrollTop + referenceRect.height - 40; // Adjust as needed
      console.log(newTopPosition)
      arrowElement.style.top = `${newTopPosition}px`;
    }
  }
  