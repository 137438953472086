import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Modal,
  IconButton,
  Stack,
  Image,
  Text,
  Link,
  DefaultButton,
  PrimaryButton,
  Persona,
  PersonaSize,
  Label
} from '@fluentui/react';
import useTourStore from "../store/tourStore";
import TourPopup from "./TourPopup";


const ModelApprover = ({ approval, onClose }) => {
  const {currentTourStep, nextTourStep, previousTourStep} = useTourStore();
  const navigate = useNavigate();
  const RejectOffboard = () => {
    navigate('/');
  }
  const [activeTab, setActiveTab] = useState('onboarding');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const CloseWindowOnly = () => {
    navigate('/user-approver');
  }

  return (
    <div className="approver-model">
      <Modal
        isOpen={true}
        onDismiss={() => onClose('Close')}
        isBlocking={false}
      >
        <div>
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            title="Close dialog"
            className="model-closer"
            ariaLabel="Close dialog"
            onClick={() => onClose('Close','ksjdfkllkdsklklsllzzmzx92209312ijijz')}
            styles={{ root: { position: 'absolute', right: 0, top: 0 } }}
          />
          <Stack horizontalAlign="start" tokens={{ childrenGap: 10, padding: 20 }}>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <div style={{ color: "black", width: 60, height: 60, backgroundColor: '#fff', borderRadius: 3, display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', fontSize: 16 }}><img style={{borderRadius:3}} className='teams-tab-image' src='./approvals.png'></img></div>

              <Stack>
                <Text variant="xLarge" styles={{ root: { color: '#252424' } }}>Approvals</Text>
                <Text styles={{ root: { color: '#252424' } }}>Approval request details</Text>
              </Stack>
            </Stack>

            <Stack tokens={{ childrenGap: 10 }}>
              <Label>Requested</Label>
              {approval.Status === "Unapproved" ? (
                <Text variant="large" styles={{ root: { color: '#252424' } }}>
                  Approval Request for creating a new user with an email {approval.GeneratedEmail}
                </Text>
              ) : approval.Status === "OffboarderedWaitingForApproval" ? (
                <Text variant="large" styles={{ root: { color: '#252424' } }}>
                  Approval Request for Off Boarding user {approval.GeneratedEmail}
                </Text>
              ) : (
                <Text variant="large" styles={{ root: { color: '#252424' } }}>
                  Approval Request for creating a new user with an email {approval.GeneratedEmail}
                </Text>
              )}
              <Text styles={{ root: { color: '#252424' } }}>
                Please approve this request:
                <br />
                New user details:
                <br />
                Last Name: {approval.LastName}
                <br />
                First Name: {approval.FirstName}
                <br />
                Email: <Link href={`mailto:${approval.GeneratedEmail}`} target="_blank">{approval.GeneratedEmail}@{approval.Company}.yourdomain.com</Link>
                <br />
                It was created by {approval.requestedBy}. They will be informed of your response.
              </Text>
            </Stack>
            <div className='modal-approver-controls w-full'>

              {approval.Status === "Unapproved" ? (
                <div className="">
                  <TourPopup
                    caretDirection="right"
                    show={currentTourStep === 3}
                    text="Click here to approve"
                    className="bottom-[17px] right-[150px]"
                    onNext={() => {
                      onClose('Approved', approval.GeneratedEmail)
                    }}
                    onPrev={() => {
                      console.log('HEY')
                      previousTourStep();
                      onClose();
                    }}
                  />
                  <Stack tokens={{ childrenGap: 10 }} styles={{ root: { padding: 20, border: '1px solid #ccc', borderRadius: 4 } }}>
                    <Text variant="mediumPlus" styles={{ root: { fontWeight: 'bold' } }}>
                      Status: {approval.status.props.children}
                    </Text>
                    <Stack tokens={{ childrenGap: 10 }}>
                      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                        <Persona
                          text={approval.sentTo}
                          secondaryText="Pending response"
                          initialsColor={4}
                          initialsTextColor="white"
                          size={PersonaSize.size40}
                        />
                      </Stack>
                      <Stack horizontal className='user-requested-approver' verticalAlign="center" tokens={{ childrenGap: 10 }}>
                        <Persona
                          text={approval.requestedBy}
                          secondaryText="Requested by"
                          initialsColor={2}
                          initialsTextColor="white"
                          size={PersonaSize.size40}
                          style={{paddingRight:"0px",marginLeft:"auto !important"}}
                        />
                      </Stack>
                    </Stack>
                    {/* <Text variant="smallPlus" styles={{ root: { textAlign: 'right', marginTop: 10 } }}>
                                        {approval.createdDateClean}
                                    </Text>OffBoardApproved */}
                  </Stack>

                  <Stack horizontal tokens={{ childrenGap: 10, padding: '20px 0' }} horizontalAlign="space-between">
                    <DefaultButton disabled text="Reject" onClick={() => onClose('Rejected', approval.GeneratedEmail)} />
                    <DefaultButton text="Approve" onClick={() => onClose('Approved', approval.GeneratedEmail)} />
                  </Stack>
                </div>
              ) : approval.Status === "OffboarderedWaitingForApproval" ? (
                <div>
                  <TourPopup
                    caretDirection="right"
                    show={currentTourStep === 11}
                    text="Click here to approve"
                    className="bottom-[17px] right-[150px]"
                    onNext={() => {
                      onClose('OffBoardApproved', approval.GeneratedEmail)
                    }}
                    onPrev={() => {
                      previousTourStep();
                      onClose();
                    }}
                  />
                  <Stack tokens={{ childrenGap: 10 }} styles={{ root: { padding: 20, border: '1px solid #ccc', borderRadius: 4 } }}>
                    <Text variant="mediumPlus" styles={{ root: { fontWeight: 'bold' } }}>
                      Status: {approval.status.props.children}
                    </Text>
                    <Stack tokens={{ childrenGap: 10, flexDirection: 'column' }} verticalAlign="center">
                      <Stack style={{ root: { paddingRight: 20 } }}>
                        <Persona
                          text={approval.sentTo}
                          secondaryText="Pending response"
                          initialsColor={4}
                          initialsTextColor="white"
                          size={PersonaSize.size40}
                        />
                      </Stack>
                      <Stack style={{ root: { paddingRight: 20 } }}>
                        <Persona
                          text={approval.requestedBy}
                          secondaryText="Requested by"
                          initialsColor={2}
                          initialsTextColor="white"
                          size={PersonaSize.size40}
                        />
                      </Stack>
                    </Stack>
                    <Text variant="smallPlus" styles={{ root: { textAlign: 'right', marginTop: 10 } }}>
                      {approval.createdDateClean}
                    </Text>
                  </Stack>
                  <Stack horizontal tokens={{ childrenGap: 10, padding: '20px 0' }} horizontalAlign="space-between">
                    <DefaultButton  text="Reject" onClick={() => RejectOffboard()} />
                    <PrimaryButton text="Approve" onClick={() => onClose('OffBoardApproved', approval.GeneratedEmail)} />
                  </Stack>
                </div>
              ) : (
                <div>
                  <Stack tokens={{ childrenGap: 10 }}>
                    <Text variant="large">Final status: Approved</Text>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                      <Persona
                        text="Department Manager"
                        secondaryText="Approved by"
                        size={PersonaSize.size40}
                        imageInitials="GR"
                      />
                      <Text>6/12/2024, 2:26:11 PM</Text>
                    </Stack>
                    <Stack horizontal className='requested-user-approver' tokens={{ childrenGap: 10 }}>
                      <Persona
                        text="Awesome User"
                        secondaryText="Requested by"
                        size={PersonaSize.size40}
                        imageInitials="AA"
                      />
                    </Stack>
                  </Stack>
                </div>
              )}
            </div>
          </Stack>
        </div>
      </Modal>
    </div>
  );
};

export default ModelApprover;
