import React, { act, useState } from 'react';
import ModelApprover from './ModelApprover';
import { UserModifier } from '../utils/UserModifier';
import { DefaultButton, DetailsList, DetailsListLayoutMode, IColumn, Modal, Text, Stack,SelectionMode, IconButton} from '@fluentui/react';
import { makeStyles, Tab, TabList } from "@fluentui/react-components";
import { useNavigate } from 'react-router-dom';
import useTourStore from "../store/tourStore";
import TourPopup from "./TourPopup";

const UserApprover = () => {
  const navigate = useNavigate();
  const {setTourState} = useTourStore();
  const [hasBeenClosed, setHasBeenClosed] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const {currentTourStep, nextTourStep, previousTourStep} = useTourStore();

  const showPopup = (item) => {
    if (currentTourStep === 2 || currentTourStep === 10) {
      nextTourStep()
    }

    if (!hasBeenClosed) {
      setPopupData(item);
      setIsPopupVisible(true);
    }
  };

  const closePopup = (action, UserEmail) => {
    navigate("/user-approver")
    // console.log(`UserEmail: ${UserEmail}`);
    // console.log(`Action: ${action}`);

    if (action === 'Approved') {
      setTourState({currentTourStep: 4})
    }

    if (action === 'OffBoardApproved') {
      setTourState({currentTourStep: 12})
    }
    console.log("closePopup is running")
    const isPopupVisible = false
    setIsPopupVisible(false);   // Ensure the modal is closed after updating the user
    const modifierReturn = UserModifier(action, UserEmail);
    console.log(isPopupVisible)
    console.log(modifierReturn)
    setHasBeenClosed(true);
    // navigate("/")
    // Reset `hasBeenClosed` to false after 50ms
    setTimeout(() => {
      setHasBeenClosed(false);
    }, 50);

  };


  const storedUsers = sessionStorage.getItem('users');
  const approvals = storedUsers ? JSON.parse(storedUsers) : [];

  const columns = [
    { key: 'column1', name: 'Request Title', fieldName: 'title', minWidth: 300, maxWidth: 600, isResizable: true },
    { key: 'column2', name: 'Status', fieldName: 'status', minWidth: 90, maxWidth: 90, isResizable: true },
    { key: 'column3', name: 'Created', fieldName: 'createdDateClean', minWidth: 100, maxWidth: 150, isResizable: true },
    { key: 'column4', name: 'Requested By', fieldName: 'requestedBy', minWidth: 100, maxWidth: 150, isResizable: true },
    { key: 'column5', name: 'Sent To', fieldName: 'sentTo', minWidth: 100, maxWidth: 150, isResizable: true },
  ];

  const items = approvals
    .filter(approval => approval.Status !== "Approved")
    .map((approval, index) => ({
      key: index,
      title: `Approval Request for ${approval.Status === "OffboarderedWaitingForApproval" ? 'Offboarding a user' : 'creating a new user'} with an email ${approval.GeneratedEmail}@${approval.Company}.yourdomain.com`,

      status: approval.Status === "Unapproved" ? <span className="css-263">Requested</span> :
        approval.Status === "Rejected" ?  <span className="css-267">Rejected</span>:
          approval.Status === "Approved" ? <span className="css-326">Approved</span> :
            approval.Status === "OffboarderedWaitingForApproval" ? <span className="css-263">Requested</span> :
              approval.Status === "OffBoardApproved" ? <span className="css-326">Approved</span>  : "OffBoardApproved",


      createdDateClean: approval.createdDateClean,
      requestedBy: 'Awesome User',
      sentTo: 'Awesome Manager',
      ...approval // Spread the approval object to pass all its properties
    }));

  return (
    <div className="">
      <TourPopup
        caretDirection="right"
        show={currentTourStep === 2 || currentTourStep === 10}
        text="Review and approve request"
        className="!fixed top-[170px] left-[70px]"
        onNext={() => {
          showPopup(items?.[items?.length - 1])
        }}
        onPrev={() => {
          previousTourStep();
          navigate('/');
        }}
      />
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }} styles={{ root: { padding: 0,paddingLeft:20 ,height:60, borderBottom: '1px solid #ddd' } }}>
        <Stack.Item>
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
            <div style={{ color: "black", width: 30, height: 30, backgroundColor: '#fff', borderRadius: 3, display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', fontSize: 16 }}><img style={{borderRadius:3}} className='teams-tab-image' src='./approvals.png'></img></div>
            <Text style={{fontSize:16,fontWeight:"bold", paddingRight:5}} variant="large">Approvals</Text>
          </Stack>
        </Stack.Item>
        <Stack.Item style={{fontSize:12}} className='stack-'>
          Sent{isPopupVisible}
        </Stack.Item>

        <Stack.Item>
          <Tab className='teams-menu-tab' title="List"  text="list">
            Received
          </Tab>
        </Stack.Item>




        <Stack.Item grow />
      </Stack>


      <div className="requests-container">
        <Text variant="xLarge">Approvals</Text>

        {isPopupVisible == true && (
          <Modal
            isOpen={isPopupVisible}
            onDismiss={() => setIsPopupVisible(false)}
            isBlocking={false}
          >
            <ModelApprover approval={popupData} onClose={closePopup} />
          </Modal>
        )}
        <DetailsList
          items={items}
          columns={columns}
          setKey="set"
          layoutMode={DetailsListLayoutMode.justified}
          selectionPreservedOnEmptyClick={true}
          selectionMode={SelectionMode.none}
          // onItemInvoked={showPopup}
          onItemInvoked={item => showPopup(item)}
          // onItemInvoked={item => showPopup(item)}  // Use onItemInvoked to handle item clicks

          onActiveItemChanged={item => showPopup(item)}
        />

      </div>
    </div>
  );
};

export default UserApprover;
